.terms-conditions h1{
  font-size: 2.5em;
}
.terms-conditions h2{
  font-size: 1.6em;
  margin-bottom: 5px;
}
.terms-conditions h3{
  font-size: 1.3em;
  margin-bottom: 5px;
}
.terms-conditions .larger{
  font-size: 1.1em;
}
.left-indent{
  margin-left: 10%;
  line-height: 18pt;
}

.terms-content{
  padding-left: 15%;
  padding-right: 15%;
}
.terms-content ul{
  padding-left: 5%;
  padding-right: 5%;
  list-style-type: disc;
} 