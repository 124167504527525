/* upload.css */

.u-container {
  max-width: 500px;
  margin: 0 auto;
  padding: 20px;
  background-color: #aeacac;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.u-container h1 {
  margin-bottom: 20px;
  font-size: 24px;
  text-align: center;
}

.u-container form {
  display: flex;
  flex-direction: column;
}

.u-container input[type="text"],
.u-container input[type="file"],
.u-container button,
.u-container select { /* Add styles for select tag */
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #020202;
  border-radius: 5px;
  font-size: 16px;
}

.u-container input[type="file"] {
  padding: 5px;
}

.u-container button {
  background-color: #007bff;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.u-container button:hover {
  background-color: #0056b3;
}

/* Style the select tag */
.u-container select {
  appearance: none; /* Removes default appearance (arrow) */
  background-color: #fff; /* Background color */
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3E%3Cpath fill='%23000000' d='M10 12.18L5.29 7.47a1 1 0 011.42-1.42L10 9.35l3.29-3.3a1 1 0 111.42 1.42L10 12.18z'/%3E%3C/svg%3E"); /* Arrow icon */
  background-repeat: no-repeat; /* No repeat */
  background-position: right 10px center; /* Position the arrow icon */
  padding-right: 30px; /* Space for arrow icon */
}
