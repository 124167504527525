.privacy-policy h1{
  font-size: 2.5em;
}
.privacy-policy h2{
  font-size: 2em;
  margin-bottom: 5px;
}
.privacy-policy h3{
  font-size: 1.3em;
  margin-bottom: 5px;
}
.left-indent{
  margin-left: 10%;
  line-height: 18pt;
}

.policy-content{
  padding-left: 15%;
  padding-right: 15%;
}
.policy-content ul{
  padding-left: 5%;
  padding-right: 5%;
  list-style-type: disc;
} 