.form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column; /* Ensure elements are stacked vertically */
}

.form-heading {
  margin-bottom: 20px;
}

.form {
  width: 100%;
  max-width: 400px; /* Adjust the maximum width as needed */
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

.form input,
.form select,
.form button {
  width: 100%;
  margin-bottom: 20px;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
  transition: border-color 0.3s ease;
}

.form input:focus,
.form select:focus {
  border-color: #4a90e2; /* Change the focus border color as needed */
}

.form button {
  background-color: #4a90e2; /* Change the button background color as needed */
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.form button:hover {
  background-color: #357bd8; /* Change the button hover background color as needed */
}
