/* CSS styles */
body {
    background-color: black;
    color: white;
    margin: 0;
  }
  
  .heading-container {
    margin: 20px;
    margin-top: 20px;
    margin-bottom: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px; /* Adjust the height as needed */
  }
  
  .text-center {
    text-align: center;
  }
  
  .maincontainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
  
  .card-container {
    flex: 0 0 calc(25% - 60px); /* Adjust the width for 3 cards in a row with a gap of 20px */
    margin: 0 60px 50px; /* Adjust the margin to reduce space between cards and add space between rows */
  }
  
  /* Card styles */
  .thecard {
    box-shadow: 5px 5px 20px rgb(74, 72, 72);
    width: 100%;
    height: 320px;
    border-radius: 10px;
    position: relative;
    transform-style: preserve-3d;
    transition: all 0.8s ease;
  }
  
  .thecard:hover {
    transform: rotateY(180deg);
  }
  
  .thefront,
  .theback {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%; /* Ensure front face covers the entire card */
    height: 100%; /* Ensure front face covers the entire card */
    border-radius: 10px;
    backface-visibility: hidden;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .thefront img {
    width: 100%; /* Ensure image fills the entire front face */
    height: 100%; /* Ensure image fills the entire front face */
    object-fit: cover; /* Maintain aspect ratio and cover entire space */
  }
  
  .thefront {
    color: #2b2c2b;
  }
  
  .theback {
    background: rgba(255, 255, 255, 0.7);
    color: black;
    text-align: center;
    transform: rotateY(180deg);
  }
 
  .theback-content {
    margin: 0 50px; /* Add padding around the content */
    text-align: center;
  }
  
  .name {
    position: absolute;
    bottom: 10px; /* Adjust positioning as needed */
    left: 10px; /* Adjust positioning as needed */
    color: white;
    font-size: 23px;
    opacity: 1;
    transition: opacity 0.5s;
  }
  
  .thecard:hover .name {
    opacity: 0; /* Hide the name on hover */
  }
  .name{
    color:black;
  }
  