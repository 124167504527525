.about {
  
  background-color:black;

  

}
.about .heading {
  text-align: center;
  width: 100%;
  margin-bottom: 30px;
  color: red !important;
}
.about p {
  font-size: 15px;
  padding-right: 30px;
  margin-bottom: 20px;
  line-height: 30px;
  color:grey;
 
}
@media screen and (max-width: 768px) {
  .about .container {
    flex-direction: column;
    
  }
  .about p {
    padding: 0;
  }
  .about .container img {
    margin-top: 100px;
   

  }
}
