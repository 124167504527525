/* Base styles */
.flex {
    display: flex;
  }
  
  .items-center {
    align-items: center;
  }
  
  .justify-center {
    justify-content: center;
  }
  
  .text-center {
    text-align: center;
  }
  
  .font-medium {
    font-weight: 500;
  }
  
  .font-bold {
    font-weight: bold;
  }
  
  .mb-6 {
    margin-bottom: 1.5rem;
  }
  
  .w-80 {
    width: 20rem;
  }
  
  .flex-col {
    flex-direction: column;
  }
  
  .gap-4 {
    gap: 1rem;
  }
  
  .rounded-lg {
    border-radius: 0.5rem;
  }
  
  .p-4 {
    padding: 1rem;
  }
  
  .leading-normal {
    line-height: 1.5;
  }
  
  .w-fit {
    width: fit-content;
  }
  
  .mx-auto {
    margin-left: auto;
    margin-right: auto;
  }
  
  .rounded-full {
    border-radius: 9999px; /* Large value for rounded circle */
  }
  
  .py-2-5 {
    padding-top: 0.625rem; /* Adjust padding as needed */
    padding-bottom: 0.625rem; /* Adjust padding as needed */
  }
  
  /* Specific styles */
  .bg-black {
    background-color: black;
  }
  
  .h-screen {
    min-height: 100vh;
  }
  
  .text-white {
    color: white;
  }
  
  .text-2xl {
    font-size: 1.5rem;
  }
  
  .text-3xl {
    font-size: 1.875rem;
  }
  
  .text-xl {
    font-size: 1.25rem;
  }
  
  .bg-white {
    background-color: white;
  }
  
  .text-black {
    color: black;
  }
  /* Add this style to set the OTP container background color to white */

 
/* otp.css */

/* Style for the OTP container */
/* Specific styles for OTP container and gap */
.otp-container {
    display: flex;
    background-color:white;
    justify-content: center;
    gap: 0.5rem; /* Adjust the gap between OTP input boxes */
    margin-top: 1rem; /* Add margin if needed */
  }
  
  .otp-input {
    width: 3rem; /* Adjust the width of each OTP input box */
    height: 3rem; /* Adjust the height of each OTP input box */
    font-size: 1.5rem; /* Adjust the font size */
    text-align: center;
    border: 1px solid #ccc;
    border-radius: 0.25rem;
    outline: none;
  }
  
  .otp-gap {
    width: 0.5rem; /* Width of the black gap between OTP input boxes */
    height: 3rem; /* Height to match input box */
    background-color: black; /* Color of the black gap */
  }
  
  
  /* Style for the Verify OTP button */
  .verify-otp-btn {
    background-color: black;
    color: white;
    width: 100%; /* Full width */
    padding: 0.75rem;
    border: none;
    border-radius: 0.5rem;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .verify-otp-btn:hover {
    background-color: #333; /* Darker shade on hover */
  }
  