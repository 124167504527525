.featured .box {
  box-shadow: 0 0 20px 0 rgb(112 121 138 / 18%);
  border-radius: 6px;
  text-align: center;
  padding: 30px;
  cursor: pointer;
  color: aliceblue;
  background-color: #232324; /* Add your desired background color here */
}
.featured img {
  width: 65px;
  height: 65px;
  margin: auto;
}
.services
{
  background-color: black; /* Add your desired background color here */
}
.services .heading {
  text-align: center;
  width: 100%;
  margin-bottom: 30px;
}